<template>
    <div class="home">
        <div class="swiper">
            <swiper
                :modules="modules"
                :loop="true"
                :slides-per-view="1"
                :space-between="50"
                :autoplay="{delay: 3000,disableOnInteraction: false}"
                :navigation="navigation"
                :pagination="{ clickable: true }"
                :scrollbar="{ draggable: false }"
                class="swiperBox"
            >
                <swiper-slide>
                    <div class="swiper-item one">
                        <p class="title">国内专业的软件开发商</p>
                        <p class="sub_title">以优质的服务，专业的产品，丰富的解决方案，助力客户更好的提升业务能力。</p>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="swiper-item two">
                        <p class="title">众多客户的信赖之选</p>
                        <p class="sub_title">通过专业服务流程，务实的服务态度，赢得客户的好评！</p>
                    </div>
                </swiper-slide>
                <div class="swiper-button-prev"/>
                <div class="swiper-button-next"/>
            </swiper>
        </div>
        
        <!-- <div class="card">
            <div class="card_item" @click="link('http://zhxq.zufengst.com:8180')">小区管理后台</div>
            <div class="card_item" @click="link('http://zbcg.zufengst.com:8182')">周边采购后台</div>
            <div class="card_item">敬请期待</div>
            <div class="card_item">敬请期待</div>
        </div> -->
        <titleCom :title="'热门产品推荐'"></titleCom>
        <div style="display: flex;justify-content: flex-end;width: 1200px;margin: 0 auto;margin-bottom: 10px;">
            <p style="color: #7f7f7f;font-size: 18px;cursor: pointer;" @click="router.push('/cgal')">查看全部></p>
        </div>
        <div class="product_list">
            
            <div class="product_item" v-for="(item,index) in productList" :key="index">
                <div class="img bg1" :style="{backgroundImage:`url(${item.img})`}">
                    <div class="product_title">{{ item.name }}</div>
                    <div class="product_btn" @click="toDetail(item.name)">了解详情</div>
                </div>
                <div class="jj">{{ item.jj }}</div>
            </div>
            <!-- <div class="product_item">
                <div class="img bg2">
                    <div class="product_title">资规一体化系统定制平台</div>
                    <div class="product_btn">了解详情</div>
                </div>
                <div class="jj">征地业务管理系统涵盖土地征收工作全流程，旨在规范土地征收工作程序，提高土地征收工作效率，自动保存过程资料，建设土地征收数据库，实时共享征地档案，从而实现土地征收工作数字化、规范化、网络化管理。</div>
            </div>
            <div class="product_item">
                <div class="img bg3">
                    <div class="product_title">祖风居</div>
                    <div class="product_btn">了解详情</div>
                </div>
                <div class="jj">征地业务管理系统涵盖土地征收工作全流程，旨在规范土地征收工作程序，提高土地征收工作效率，自动保存过程资料，建设土地征收数据库，实时共享征地档案，从而实现土地征收工作数字化、规范化、网络化管理。</div>
            </div> -->
        </div>
        <titleCom :title="'专业的服务流程'"></titleCom>
        <p style="color: #aaa;font-size: 16px;">服务全流程由项目经理1对1跟进服务质量和进度</p>
        <div class="flow">
            <div style="padding: 60px;">
                <div class="imgs">
                    <img src="@/assets/u70.svg" alt="">
                    <img src="@/assets/u65.svg" alt="">
                    <img src="@/assets/u67.svg" alt="">
                    <img src="@/assets/u65.svg" alt="">
                    <img src="@/assets/u69.svg" alt="">
                    <img src="@/assets/u65.svg" alt="">
                    <img src="@/assets/u72.svg" alt="">
                    <img src="@/assets/u65.svg" alt="">
                    <img src="@/assets/u75.svg" alt="">
                    <img src="@/assets/u65.svg" alt="">
                    <img src="@/assets/u76.svg" alt="">
                </div>
                <div class="word">
                    <p>沟通需求</p>
                    <p>方案演示</p>
                    <p style="margin-left: 10px;">签订合同</p>
                    <p>产品开发测试</p>
                    <p>产品交付</p>
                    <p>售后运营</p>
                </div>
            </div>
            <div class="disc">
                <div class="disc_item">与客户沟通业务详情和诉求，针对业务痛点，挖掘真实需求。</div>
                <div class="disc_item">根据客户需求，制定产品解决方案并与客户确认演示。</div>
                <div class="disc_item">根据确认的需求评估工期，与客户确认报价并签订合同。</div>
                <div class="disc_item">与客户确认产品原型。开始产品研发，测试工作。</div>
                <div class="disc_item">交付测试无误的项目给客户验收，免费提供产品操作培训服务。</div>
                <div class="disc_item">通用功能优化升级服务，1年免费运营维护。</div>
            </div>
        </div>
        <titleCom :title="'我们的客户&案例'"></titleCom>
        <div class="hzxx">
            <swiper
                :modules="modules"
                :loop="false"
                :slides-per-view="3"
                :slides-per-group="3"
                :space-between="30"
                :autoplay="{delay: 3000,disableOnInteraction: false}"
                :navigation="navigation"
                :pagination="{ clickable: true }"
                :scrollbar="{ draggable: false }"
                style="height: 400px;"
            >
                <swiper-slide v-for="(item,index) in hzList" :key="index">
                    <div class="hzxx_item" >
                        <div class="hzxx_item_img" :style="{backgroundImage:`url(${item.url})`}"></div>
                        <div class="hzxx_item_name">{{ item.Dw }}</div>
                        <div class="hzxx_item_jj">{{ item.Jj }}</div>
                    </div>
                </swiper-slide>
                <div class="swiper-button-prev"/>
                <div class="swiper-button-next"/>
            </swiper>
            
        </div>
    </div>
</template>

<script setup>
import { ref,getCurrentInstance,onMounted } from 'vue'
import {previewUrl} from "@/utils/index"
import titleCom from '@/components/titleCom/titleCom.vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css/pagination' // 轮播图底面的小圆点
import 'swiper/css/navigation' // 轮播图两边的左右箭头
import {Autoplay,Navigation,Pagination,Scrollbar} from "swiper/modules";
import {useRouter} from 'vue-router'
import {userStore} from '@/store/index'
const store = userStore()

const { proxy } = getCurrentInstance()
const router = useRouter()
//在modules加入要使用的模块
const modules = [Autoplay, Pagination, Navigation, Scrollbar];
const navigation = ref({
  nextEl: ".swiper-button-next",
  prevEl: ".swiper-button-prev",
});
// const navigation2 = ref({
//   nextEl: ".swiper-button-next2",
//   prevEl: ".swiper-button-prev2",
// });
const hzList = ref([])
const productList = ref([
    {name:'征地管理综合信息系统',img:require('@/assets/pd1.png'),jj:'征地业务管理系统涵盖土地征收工作全流程，旨在规范土地征收工作程序，提高土地征收工作效率，自动保存过程资料，建设土地征收数据库，实时共享征地档案，从而实现土地征收工作数字化、规范化、网络化管理。'},
    {name:'资规一体化系统定制平台',img:require('@/assets/pd2.png'),jj:'本系统是一款业务管理软件。为实现自然资源和规划的数字化管理以提高工作效率，可配置业务流程适应多变的业务需求降低系统建设成本。'},
    {name:'祖风居',img:require('@/assets/pd3.png'),jj:'小区业主共享小区管理日常资讯，推进小区民主自治，提供周边生活资讯，方便日常生活，提供农业与农产品资讯，为小区业主走进大自然，体验田园生活创造条件。'},
])

onMounted(() => {
    getHzxx()
})

// const link = (url) => {
//     window.open(url,"__blank")
// }



const toDetail = (name) => {
    // if(name == '资规一体化系统定制平台' || name == '征地管理综合信息系统') {
        store.setCpmc(name)
        sessionStorage.setItem('cpmc',name)
        router.push('/cpDetail')
    // }
}

const getHzxx = () => {
    proxy.$get('/api/Mhwz/GetHzxxList',{page:1,limit:100,key:''}).then(res => {
        if(res.code == 200 && res.result) {
            res.data.map(i => {
                if(i.File.length) {
                    i.url = previewUrl + i.File[0].Fdz
                }
            })
            hzList.value = res.data
        }
    })
};

</script>

<style lang="scss" scoped>
.home{
    .swiper{
        width: 100%;
        height: 700px;
        :deep(.swiper-pagination) .swiper-pagination-bullet.swiper-pagination-bullet-active {
            background-color: #fff;
        }
        .one{
            background-image: url('@/assets/banner1.jpg');
            background-position: 50% 50%;
        }
        .two{
            background-image: url('@/assets/banner2.jpg');
            background-position: 50% 50%;
        }
        .swiper-item{
            position: relative;
            width: 100%;
            height: 100%;
            .title{
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                top: 200px;
                font-size: 60px;
                color: #fff;
                font-weight: 650;
            }
            .sub_title{
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                top: 300px;
                font-size: 28px;
                color: #fff;
                font-weight: 400;
                width: 100%;
            }
        }
        .swiper-button-prev,.swiper-button-next {
            color: #999;
        }
    }
    .card{
        width: 1260px;
        margin: 0 auto;
        margin-top: 30px;
        height: 64px;
        background-color: rgba(215, 215, 215, 1);
        padding: 7px 0;
        box-sizing: border-box;
        display: flex;
        justify-content: space-evenly;
        .card_item{
            width: 200px;
            height: 50px;
            line-height: 50px;
            border-radius: 10px;
            color: #fff;
            border: 1px solid #1abc9c;
            //background-image: url('@/assets/logo.png');
            background-color: rgba($color: #1891ff, $alpha: 0.7);
            background-position: 50% 50%;
            &:hover{
                opacity: 0.5;
                cursor: pointer;
            }
        }
    }

    .product_list{
        width: 1200px;
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
        .product_item{
            width: 385px;
            .bg1{
                background-image: url('@/assets/pd1.png');
            }
            .bg2{
                background-image: url('@/assets/pd2.png');
            }
            .bg3{
                background-image: url('@/assets/pd3.png');
            }
            .img{
                width: 100%;
                height: 225px;
                position: relative;
                background-color: rgba(0, 0, 0, 0.6);
                background-blend-mode: multiply;
                .product_title{
                    width: 100%;
                    position: absolute;
                    left: 50%;
                    top: 40%;
                    transform: translate(-50%,-50%);
                    font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
                    font-weight: 650;
                    font-style: normal;
                    font-size: 28px;
                    color: #FFFFFF;
                    line-height: 48px;
                }
                .product_btn{
                    width: 120px;
                    height: 35px;
                    box-sizing: border-box;
                    line-height: 35px;
                    border-radius: 20px;
                    border: 1px solid #fff;
                    position: absolute;
                    left: 50%;
                    top: 60%;
                    transform: translate(-50%,-50%);
                    font-size: 14px;
                    color: #fff;
                    cursor: pointer;
                }
            }
            .jj{
                background-color: #fff;
                padding: 20px 30px 0px 30px;
                box-sizing: border-box;
                font-family: 'PingFangSC-Regular', 'PingFang SC', sans-serif;
                font-weight: 400;
                font-style: normal;
                color: #999999;
                text-align: left;
                line-height: 24px;
                font-size: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
            }
        }
    }

    .flow{
        background-color: #fff;
        width: 1200px;
        height: 425px;
        margin: 30px auto;
        text-align: center;
        // padding: 60px;
        box-sizing: border-box;
        .imgs{
            // width: 1100px;
            // margin: 0 auto;
            padding-left: 10px;
            display: flex;
            align-items: center;
            img{
                display: block;
            }
        }
        .word{
            display: flex;
            justify-content: space-between;
            color: #409eff;
            margin-top: 10px;
        }
        .disc{
            display: flex;
            justify-content: space-between;
            padding: 0 25px;
            .disc_item{
                width: 124px;
                height: 173px;
                box-sizing: border-box;
                padding: 20px 20px 0;
                font-family: 'MicrosoftYaHei', 'Microsoft YaHei', sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 14px;
                // text-align: left;
                border-width: 1px;
                border-style: solid;
                border-color: rgba(233, 233, 233, 1);
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0470588235294118);
                color: #7F7F7F;
                line-height: 25px;
                text-align: justify;
            }
        }
    }

    .hzxx{
        //display: flex;
        // display: grid;
        // grid-template-columns: 385px 385px 385px;
        // grid-template-columns: repeat(3);
        // column-gap: 21px;
        // justify-content: space-between;
        width: 1200px;
        box-sizing: border-box;
        // height: 425px;
        margin: 0 auto;
        background-color: #fff;
        padding-top: 30px;
        .hzxx_item{
            width: 100%;
            height: 225px;
            box-sizing: border-box;
            .hzxx_item_img{
                width: 100%;
                height: 100%;
                // border: 1px solid #999;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-position: 100% 100%;
            }
            .hzxx_item_name {
                color: #666;
                margin: 10px 0;
            }
            .hzxx_item_jj {
                color: #999;
                font-size: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
            }
        }
    }
}
</style>
