import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/cgal',
    name: 'cgal',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/cgalView.vue')
  },
  {
    path: '/dzkf',
    name: 'dzkf',
    component: () => import('../views/dzkffwView.vue')
  },
  {
    path: '/xwdt',
    name: 'xwdt',
    component: () => import('../views/xwdtView.vue')
  },
  {
    path: '/shbz',
    name: 'shbz',
    component: () => import('../views/shbzView.vue')
  },
  {
    path: '/gywm',
    name: 'gywm',
    component: () => import('../views/gywmView.vue')
  },
  {
    path: '/xwDetail/:id',
    name: 'xwDetail',
    component: () => import('../views/xwDetail.vue')
  },
  {
    path: '/cpDetail',
    name: 'cpDetail',
    component: () => import('../views/cpDetail.vue')
  },
  {
    path: '/czsc',
    name: 'czsc',
    component: () => import('../views/czscView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
