// import { createStore } from 'vuex'
import { defineStore } from 'pinia';

export const userStore = defineStore('main', {
    state: () => {
        return {
            cpmc:'',
            czscForCp:''
        }
    },
    actions:{
        setCpmc(value) {
            this.cpmc = value
        },
        setCzscForCp(value) {
            this.czscForCp = value
        }
    }

})

// export default createStore({
//   state: {
//   },
//   getters: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })
