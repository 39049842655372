<template>
    <div class="title-com">
        {{ props.title }}
    </div>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
    title: {
        type: String,
        default: function () { return ''}
    }
});
</script>

<style lang="scss" scoped>
.title-com {
    font-weight: 500;
    margin-top: 40px;
    margin-bottom: 30px;
    color: #333;
    font-size: 30px;
}
</style>