<template>
    <!-- <div class="main"> -->
        <div class="footer">
            <p class="title">联系我们</p>
            <p class="sub_title">为你想得更多，为你做得更好</p>
            <div class="content">
                <div class="left">
                    <p>杭州祖风科技有限公司</p>
                    <ul>
                        <li>公司详细地址：杭州市西湖区体育场路498号</li>
                        <li>电话：0571-86956769</li>
                        <li>邮箱：zufengst@qq.com</li>
                        <li>技术支持：7*24小时</li>
                    </ul>
                    <p>官方微信：</p>
                    <div class="code">
                        <div>
                            <img src="@/assets/kf.png" alt="" srcset="">
                            <span>官方微信客服</span>
                        </div>
                        <div style="margin-left: 60px;">
                            <img src="@/assets/gzh.png" alt="" srcset="">
                            <span>官方微信公众号</span>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <p>留言给我们</p>
                    <el-form :model="form" label-width="0" ref="formRef" size="large" :rules="rules">
                        <el-row>
                            <el-col :span="24">
                                <el-form-item label="" prop="Mc">
                                    <el-input v-model="form.Mc" placeholder="请输入您的称呼/姓名"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="" prop="Dh">
                                    <el-input v-model="form.Dh" placeholder="请输入您的联系电话"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="" prop="Vx">
                                    <el-input v-model="form.Vx" placeholder="请输入您的邮箱"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="" prop="Nr">
                                    <el-input type="textarea" v-model="form.Nr" :rows="4" placeholder="请输入留言信息"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-button style="width: 100%;" size="large" type="primary" @click="submit">提交</el-button>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </div>
            <div class="nav">
                <div class="top">
                    <div class="nav_item" v-for="(item,index) in navList" :key="index" :class="{active:current == index}" @click="navClick(index)">
                        {{ item }}
                    </div>
                </div>
                <div class="bottom">
                    Copyright © 2024-2029 杭州祖风科技有限公司 All Rights Reserved.
                    <img src="@/assets/beian.png" alt="" height="20px" style="margin-left: 20px;">
                    <a style="margin-left: 5px;" href="https://beian.mps.gov.cn/#/query/webSearch?code=33010602010932" rel="noreferrer" target="_blank">浙公网安备33010602010932</a>
                    <span style="margin-right: 5px;">备案号</span>
                    <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备16023957号-2</a>
                </div>
            </div>
        </div>
    <!-- </div> -->
    
</template>

<script setup>
import {getCurrentInstance,onMounted,ref,reactive,watch} from "vue";

// import Message from '@/components/messageCom/message'
import {ElMessage} from 'element-plus'
import {useRouter} from 'vue-router'
import {deepClone,encrypt} from '@/utils/index.js'
const router = useRouter()
const { proxy } = getCurrentInstance()
//手机号校验
const checkPhone = (rule, value, callback) => {
    if (!value) {
        return callback(new Error('请输入手机号'))
    }
    const sjhReg = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
    if (!sjhReg.test(value)) {
        callback(new Error('手机号格式不正确'))
    } else {
        callback()
    }
}

//邮箱校验
const checkEmail = (rule, value, callback) => {
    if (!value) {
        return callback(new Error('请输入邮箱'))
    }
    const yxReg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
    if (!yxReg.test(value)) {
        callback(new Error('邮箱格式不正确'))
    } else {
        callback()
    }
}
const rules = {
    Mc: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
    Dh: [{ required: true, validator: checkPhone, trigger: 'blur' }],
    Vx: [{ required: true, validator: checkEmail, trigger: 'blur' }],
    Nr: [{ required: true, message: '请输入介绍信息', trigger: 'blur' }],
}
const navList = ref([])
const formRef = ref(null)
const current = ref(0)
const form = reactive({
    Mc:'',
    Dh:'',
    Vx:'',
    Nr:''
})
onMounted(() => {
    getNavList()
});

const navClick = (index) => {
    current.value = index
    if(index == 0) {
        router.push('/')
    } else if(index == 1) {
        router.push('/cgal')
    } else if(index == 2) {
        router.push('/dzkf')
    } else if(index == 3) {
        router.push('/xwdt')
    } else if(index == 4) {
        router.push('/shbz')
    } else if(index == 5) {
        router.push('/gywm')
    } 
}

const submit = () => {
    formRef.value.validate((valid) => {
        if(valid) {
            let params = deepClone(form)
            params.Dh = encrypt(params.Dh)
            // params.Vx = encrypt(params.Vx)
            proxy.$post('/api/Mhwz/AddLyxx',params).then(res => {
                if(res.code == 200 && res.result) {
                    ElMessage({
                        message: "提交成功",
                        type: "success",
                    });
                } else {
                    ElMessage({
                        message: "提交失败",
                        type: "warning",
                    });
                }
            })
        } else {
            return false
        }
    })
}

watch(() =>router.currentRoute.value.path,(newValue)=> {
    if(newValue == '/') {
        current.value = 0
    } else if(newValue == '/cgal' || newValue == '/cpDetail' || newValue == '/czsc') {
        current.value = 1
    } else if(newValue == '/dzkf') {
        current.value = 2
    } else if(newValue == '/xwdt' || newValue.includes('xwDetail')) {
        current.value = 3
    } else if(newValue == '/shbz') {
        current.value = 4
    } else if(newValue == '/gywm') {
        current.value = 5
    }
},{ immediate: true })

const getNavList = () => {
    proxy.$get('/api/Xtgl/getCs', { csdm: 'nav' }).then(res => {
        if(res.code == 200 && res.result) {
            navList.value = res.data.Csz.split('，')
        }
    })
};

</script>

<style lang="scss" scoped>
.footer{
    width:1200px;
    // width: 100%;
    margin: 0 auto;
    margin-top: 20px;
    .title{
        font-size: 32px;
        color: #333;
        font-weight: 600;
    }
    .sub_title{
        color: #999;
        font-size: 20px;
        margin: 10px 0;
    }
    .content {
        margin-top: 50px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .left{
            width: 44%;
            p{
                color: #333;
                font-size: 18px;
                text-align: left;
            }
            ul{
                color: #999;
                font-size: 14px;
                text-align: left;
                list-style: none;
                margin-bottom: 30px;
                li{
                    margin: 15px 0;
                }
            }
            .code{
                display: flex;
                margin-top: 20px;
                width: 100%;
                &>div{
                    position: relative;
                    span{
                        display: block;
                        color: #999;
                        font-size: 14px;
                    }
                }
                img{
                    width: 133px;
                    height: 133px;
                    display: inline-block;
                }
                
            }
        }
        .right{
            width: 44%;
            p{
                color: #333;
                font-size: 24px;
                margin-bottom: 30px;
            }
        }
    }
    .nav{
        width: 100%;
        height: 127px;
        background-color: rgba(34, 36, 41, 0.83921568627451);
        margin-top: 20px;
        .top{
            display: flex;
            justify-content: space-around;
            padding-top: 20px;
            .nav_item{
                width: 140px;
                height: 50px;
                line-height: 50px;
                color: #fff;
                box-sizing: border-box;
                border-width: 1px;
                border-style: solid;
                border-color: rgba(51, 51, 51, 1);
                border-left: 0px;
                border-top: 0px;
                border-right: 0px;
                border-radius: 0px;
                border-bottom-right-radius: 0px;
                border-bottom-left-radius: 0px;
                cursor: pointer;
            }
            .active{
                background-color: #1891ff;
            }
        }
        .bottom {
            margin-top: 20px;
            font-size: 12px;
            // line-height: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #aaa;
            a{
                color: #aaa;
                margin-right: 30px;
                // margin-left: 10px;
            }
        }
    }
}
</style>