import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import { createPinia } from 'pinia'
import Axios from './request/axios';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'swiper/css';
import zhCn from 'element-plus/es/locale/lang/zh-cn'

router.afterEach(() => {
    window.scrollTo(0,0);
});
createApp(App).use(createPinia()).use(router).use(Axios).use(ElementPlus,{locale:zhCn}).mount('#app')
