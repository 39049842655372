<template>
  <!-- <nav>
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
    </nav> -->
  <div class="nav">
    <div class="content">
      <div class="logo">
        <img src="@/assets/logo.png" alt="" srcset="" />
        <div class="name">杭州祖风科技有限公司</div>
      </div>
      <div class="tabs">
        <div
          class="nav_item"
          v-for="(item, index) in navList"
          :key="index"
          :class="{ active: current == index }"
          @click="navClick(index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="kf">
        <div class="text">官方客服</div>
        <!-- <div class="wx">
                    <img src="@/assets/wx.png" alt="" srcset="">
                    <img class="wx2" src="@/assets/code.png" alt="" srcset="">
                </div> -->
        <el-tooltip
          effect="light"
          :content="`<span><img style='width:120px' src='${baseUrl}/code.png'/></span>`"
          placement="bottom"
          raw-content
        >
          <img src="@/assets/wx.png" alt="" srcset="" />
        </el-tooltip>
      </div>
    </div>
  </div>
  <router-view />
  <div class="right_box">
    <a
      href="http://zhxq.zufengst.com:8180"
      target="_blank"
      rel="noopener noreferrer"
      >小区管理后台</a
    >
    <a
      href="http://zbcg.zufengst.com:8182"
      target="_blank"
      rel="noopener noreferrer"
      >祖风周边后台</a
    >
    <a href="javascript:void(0);" rel="nofollow" @click="download"
      >祖风居App-安卓</a
    >
  </div>
  <footerCom />
</template>

<script setup>
import { getCurrentInstance, onMounted, ref, watch } from "vue";
import footerCom from "@/components/footerCom/footerCom.vue";
import { useRouter } from "vue-router";
import { baseUrl } from "@/utils/index";

const router = useRouter();

const { proxy } = getCurrentInstance();
const navList = ref([]);
const current = ref(0);

onMounted(() => {
  getNavList();
});

watch(
  () => router.currentRoute.value.path,
  (newValue) => {
    if (newValue == "/") {
      current.value = 0;
    } else if (
      newValue == "/cgal" ||
      newValue == "/cpDetail" ||
      newValue == "/czsc"
    ) {
      current.value = 1;
    } else if (newValue == "/dzkf") {
      current.value = 2;
    } else if (newValue == "/xwdt" || newValue.includes("xwDetail")) {
      current.value = 3;
    } else if (newValue == "/shbz") {
      current.value = 4;
    } else if (newValue == "/gywm") {
      current.value = 5;
    }
  },
  { immediate: true }
);

const download = () => {
  const a = document.createElement("a");
  a.href = "https://back.zufengst.com:444/zfhome.apk";
  a.download = "zfhome.apk";
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  a.remove();
};

const navClick = (index) => {
  current.value = index;
  if (index == 0) {
    router.push("/");
  } else if (index == 1) {
    router.push("/cgal");
  } else if (index == 2) {
    router.push("/dzkf");
  } else if (index == 3) {
    router.push("/xwdt");
  } else if (index == 4) {
    router.push("/shbz");
  } else if (index == 5) {
    router.push("/gywm");
  }
};

const getNavList = () => {
  proxy.$get("/api/Xtgl/getCs", { csdm: "nav" }).then((res) => {
    if (res.code == 200 && res.result) {
      navList.value = res.data.Csz.split("，");
    }
  });
};
</script>

<style lang="scss" scoped>
.nav {
  width: 100%;
  min-width: 1260px;
  display: flex;
  justify-content: center;
  background-color: #000;
  height: 60px;
  opacity: 0.8;
  line-height: 60px;
  .content {
    width: 1260px;
    // padding: 0 200px;
    height: 60px;
    display: flex;
    position: relative;
    justify-content: space-between;
    .logo {
      width: 60px;
      height: 60px;
      display: flex;
      width: 280px;
      img {
        width: 60px;
        height: 60px;
      }
      .name {
        width: 200px;
        font-size: 20px;
        font-family: "STXingkai", sans-serif;
        font-weight: 400;
        color: #ccc;
      }
    }
    .tabs {
      width: 800px;
      display: flex;
      justify-content: space-between;
      .nav_item {
        color: #ccc;
        font-size: 14px;
        width: 120px;
        height: 100%;
        cursor: pointer;
        &:hover {
          color: #fff;
        }
      }
      .active {
        background-color: #1891ff;
      }
    }
    .kf {
      display: flex;
      .text {
        font-size: 20px;
        width: 100px;
        color: #fff;
      }
    }
  }
}

.right_box {
  width: 143px;
  height: 143px;
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 999;
  margin-left: 530px;
  a {
    text-decoration: none;
    background-color: rgba($color: #1891ff, $alpha: 0.5);
    height: 30px;
    line-height: 30px;
    color: #fff;
    display: block;
    font-size: 14px;
    &:hover {
      background-color: #1891ff;
      color: #fff;
    }
  }
}
</style>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: rgba(245, 245, 245, 1);
}
body {
  min-width: 1260px;
  // scroll-behavior: smooth;
}
</style>
